import * as React from "react";

import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { useFunctionContext } from "../FunctionContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AppointmentsTable() {
  const [rowsData, setRowsData] = React.useState([]);
  const { setTriggerFunction } = useFunctionContext();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const noOfColumns = 26;

  const setAppointments = (appointments) => {
    setPage(0);
    setRowsData(appointments);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  React.useEffect(() => {
    setTriggerFunction(() => setAppointments);
  }, [setTriggerFunction]);

  function getHighlightBorder(currentRow) {
    var border = "none";
    if (currentRow.Errors && currentRow.Errors.length > 0) {
      border = "4px solid red";
    }
    return border;
  }

  return (
    <div style={{ width: "100%" }}>
      <Box
        component="section"
        sx={{
          //p: 1,
          border: "1px solid grey",
          borderRadius: 1,
          fontWeight: "bold",
          background: "#EEF5FF",
          color: "#006098",
        }}
      >
        <legend align="center">Customer Appointments Details</legend>
        <br />
        <Paper>
          <TableContainer>
            <Table size="small" stickyHeader aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      minWidth: 120,
                      align: "center",
                    }}
                  >
                    Delivery Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 190, align: "center" }}
                  >
                    Early Delivery Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 190, align: "center" }}
                  >
                    Late Delivery Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100, align: "center" }}
                  >
                    Delivery Appointment
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 190 }}
                  >
                    Early Pickup Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 190 }}
                  >
                    Late Pickup Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Pickup Appointment
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Ref VALUE
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    CPU CONTACT
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Pending Reason Code
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Preload
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Rate Offering ID
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Ship With Group
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Dropoff Routing Seq
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Spot Image
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Spot reason code
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 150 }}
                  >
                    Scheduled Image
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 150 }}
                  >
                    File Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 150 }}
                  >
                    Time Zone
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 150 }}
                  >
                    Customer Portal Appointment Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 150 }}
                  >
                    OTM Transmission ID
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Created By
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Created Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Updated By
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 100 }}
                  >
                    Updated Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", minWidth: 120 }}
                  >
                    Errors
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsData.length === 0 ? (
                  <TableRow>
                    <TableCell scope="row" colSpan={noOfColumns}>
                      No customer approintments available
                    </TableCell>
                  </TableRow>
                ) : (
                  (rowsPerPage > 0
                    ? rowsData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rowsData
                  ).map((row, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          scope="row"
                          sx={{ borderLeft: getHighlightBorder(row) }}
                        >
                          {row.OrderReleaseId}
                        </TableCell>
                        <TableCell align="center">
                          {row.EarlyDeliveryDate
                            ? moment(
                                row.EarlyDeliveryDate,
                                "YYYY-MM-DDTHH:mm:ss tz"
                              ).format("MM/DD/YYYY, HH:mm:ss A")
                            : row.EarlyDeliveryDate}
                        </TableCell>
                        <TableCell align="center">
                          {row.LateDeliveryDate
                            ? moment(
                                row.LateDeliveryDate,
                                "YYYY-MM-DDTHH:mm:ss tz"
                              ).format("MM/DD/YYYY, HH:mm:ss A")
                            : row.LateDeliveryDate}
                        </TableCell>
                        <TableCell align="center">
                          {row.DeliveryAppointment}
                        </TableCell>
                        <TableCell align="center">
                          {row.EarlyPickupDate
                            ? moment(
                                row.EarlyPickupDate,
                                "YYYY-MM-DDTHH:mm:ss tz"
                              ).format("MM/DD/YYYY, HH:mm:ss A")
                            : row.EarlyPickupDate}
                        </TableCell>
                        <TableCell align="center">
                          {row.LatePickupDate
                            ? moment(
                                row.LatePickupDate,
                                "YYYY-MM-DDTHH:mm:ss tz"
                              ).format("MM/DD/YYYY, HH:mm:ss A")
                            : row.LatePickupDate}
                        </TableCell>
                        <TableCell align="center">
                          {row.PickupAppointment}
                        </TableCell>
                        <TableCell align="center">{row.RefValue}</TableCell>
                        <TableCell align="center">{row.CPUContact}</TableCell>
                        <TableCell align="center">
                          {row.PendingReasonCode}
                        </TableCell>
                        <TableCell align="center">{row.Preload}</TableCell>
                        <TableCell align="center">
                          {row.RateOfferingId}
                        </TableCell>
                        <TableCell align="center">
                          {row.ShipWithGroup}
                        </TableCell>
                        <TableCell align="center">
                          {row.DropOffRoutingSeq}
                        </TableCell>
                        <TableCell align="center">{row.SpotImage}</TableCell>
                        <TableCell align="center">
                          {row.SpotReasonCode}
                        </TableCell>
                        <TableCell align="center">
                          {row.ScheduledImage}
                        </TableCell>
                        <TableCell align="center">{row.FileName}</TableCell>
                        <TableCell align="center">{row.Timezone}</TableCell>

                        <TableCell align="center">
                          {row.AppointmentStatus}
                        </TableCell>
                        <TableCell align="center">
                          {row.OTMBatchUpdateTransmissionId}
                        </TableCell>
                        <TableCell align="center">{row.CreatedBy}</TableCell>
                        <TableCell align="center">
                          {"" + row.CreatedTime + " (PST)"}
                        </TableCell>
                        <TableCell align="center">{row.UpdatedBy}</TableCell>
                        <TableCell align="center">
                          {"" + row.UpdatedTime + " (PST)"}
                        </TableCell>

                        {row.Errors && row.Errors.length > 0 ? (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography sx={{ color: "red" }}>
                                Errors
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {row.Errors.map((error) => (
                                <Typography>{error.Error}</Typography>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <TableCell align="center">&nbsp;</TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={noOfColumns} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </div>
  );
}
